import React, { useEffect } from "react"
import { AppWrapper } from "../components/AppWrapper"
import { Header } from "../components/Header"
import Footer from "../components/ui/Footer"
import { WidthContainer } from "../components/WidthContainer"
import firebase from "gatsby-plugin-firebase"
import { ANALYTIC_EVENT_NAMES } from "../constants/analytics"
import {graphql} from "gatsby";
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default function IndexPage({ location }) {

    const { t } = useTranslation();
    // analytics - page viewed event
    useEffect(() => {
        if(!firebase) {
            return;
        }
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_page_viewed)
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_general_terms_and_conditions_page_viewed)
    }, [firebase])

    return (
        <AppWrapper>
            <Header {...{ location }} />
            <div id="background"
                {...{
                    style: {
                        placeItems: "center",
                        background: "#102543",
                        // height: "100%",
                        color: "white"
                    },
                }}>
                <WidthContainer center >
                    <p style={{ marginTop: 150 }}>
                        <strong>{t('terms_and_conditions.conditions_generales_title')}</strong>
                    </p>
                    <p className="c5"><span className="c4">{t('terms_and_conditions.update_notice')}</span></p>
                    <p className="c5"><span className="c4">{t('terms_and_conditions.preambule')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span><Trans i18nKey="terms_and_conditions.society_intro"></Trans></span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span><Trans i18nKey="terms_and_conditions.application_object"></Trans></span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span><Trans i18nKey="terms_and_conditions.parties_definition"></Trans></span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span><Trans i18nKey="terms_and_conditions.acceptance"></Trans></span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span>{t('terms_and_conditions.acceptance_detail')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <ol className="c3 lst-kix_list_2-0 start">
                        <li className="c0 c12 li-bullet-0"><span className="c4">{t('terms_and_conditions.objet')}</span></li>
                    </ol>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.objet_description')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0"><span className="c4">{t('terms_and_conditions.caracteristiques_services')}</span></li>
                    </ol>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span className="c1"><Trans i18nKey="terms_and_conditions.services_details"></Trans></span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.objective')}</span></p>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0"><span className="c4">{t('terms_and_conditions.user_journey_title')}</span></li>
                    </ol>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c8">3.1.</span><span> </span><span className="c4">{t('terms_and_conditions.account_creation')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.account_creation_detail')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.first_login')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span>{t('terms_and_conditions.account_creation_requirement')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span>{t('terms_and_conditions.unique_account')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.after_registration')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c8">3.2.</span><span> </span><span className="c4">{t('terms_and_conditions.identification')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.identification_detail')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.content_update')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c8">3.3.</span><span> </span><span className="c4">{t('terms_and_conditions.subscription')}</span>
                    </p>
                    <p className="c0 c2 c13"><span className="c4"></span></p>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.subscription_types')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <ol className="c3 lst-kix_list_7-0 start">
                        <li className="c0 c10 li-bullet-1"><span className="c6">{t('terms_and_conditions.monthly_offer')}</span></li>
                    </ol>
                    <br/>
                    <p className="c0 c15"><span className="c6"> </span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.monthly_offer_detail')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.monthly_debit')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.monthly_user_debit')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <ol className="c3 lst-kix_list_7-0">
                        <li className="c0 c10 li-bullet-2"><span className="c6">{t('terms_and_conditions.semi_annual_offer')}</span>
                        </li>
                    </ol>
                    <p className="c0 c2"><span className="c6"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.semi_annual_offer_detail')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.semi_annual_debit')}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.semi_annual_user_debit')}</span>
                    </p>
                    <p className="c0 c2"><span className="c6"></span></p>
                    <ol className="c3 lst-kix_list_6-0 start">
                        <li className="c0 c10 li-bullet-2">
                            <span className="c6">{t('terms_and_conditions.annual_subscription_offer')}</span>
                        </li>
                    </ol>
                    <p className="c0 c2">
                        <span className="c6"></span>
                    </p>
                    <p className="c0">
                        <span className="c1">{t('terms_and_conditions.annual_subscription_offer_detail')}</span>
                    </p>
                    <p className="c0 c2">
                        <span className="c1"></span>
                    </p>
                    <p className="c0">
                        <span className="c1">{t('terms_and_conditions.annual_subscription_subscription_benefit')}</span>
                    </p>
                    <p className="c0 c2">
                        <span className="c1"></span>
                    </p>
                    <p className="c0">
                        <span className="c1">{t('terms_and_conditions.annual_subscription_user_debit')}</span>
                    </p>
                    <p className="c0 c2">
                        <span className="c1"></span>
                    </p>
                    <p className="c0"><span className="c8">3.4.</span><span> </span><span className="c4">{t('terms_and_conditions.availability.title')}</span></p>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.availability.internet_connection')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.availability.continuous_access')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.availability.temporary_suspend')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.availability.restricted_access')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.availability.report_error')}</span></p>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0"><span className="c4">{t("terms_and_conditions.pricing_and_payment_conditions.title")}</span>
                        </li>
                    </ol>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <p className="c0">
                        <span className="c1">{t("terms_and_conditions.pricing_and_payment_conditions.product_pricing")}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span className="c1">{t("terms_and_conditions.pricing_and_payment_conditions.payment_management")}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span className="c1">{t("terms_and_conditions.pricing_and_payment_conditions.automatic_renewal")}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span className="c1">{t("terms_and_conditions.pricing_and_payment_conditions.user_information")}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span className="c1">{t("terms_and_conditions.pricing_and_payment_conditions.payment_conditions")}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <ul className="c3 lst-kix_list_4-0 start">
                        <li className="c0 c12 li-bullet-0">
                          <span className="c1">
                            {t("terms_and_conditions.pricing_and_payment_conditions.monthly_subscription")}
                          </span>
                        </li>
                        <li className="c0 c12 li-bullet-0">
                          <span className="c1">
                            {t("terms_and_conditions.pricing_and_payment_conditions.semiannual_subscription")}
                          </span>
                        </li>
                    </ul>
                    <p className="c0 c2 c13"><span className="c1"></span></p>
                    <ul className="c3 lst-kix_list_4-0">
                        <li className="c0 c12 li-bullet-0">
                          <span className="c1">
                            {t("terms_and_conditions.pricing_and_payment_conditions.annual_subscription")}
                          </span>
                        </li>
                    </ul>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span className="c1">
                          {t("terms_and_conditions.pricing_and_payment_conditions.payment_authorization")}
                        </span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span className="c1">
                          {t("terms_and_conditions.pricing_and_payment_conditions.update_payment_information")}
                        </span>
                    </p>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0">
                            <span className="c4">{t('terms_and_conditions.delivery_terms.title')}</span>
                        </li>
                    </ol>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.delivery_terms.subscription_access')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t('terms_and_conditions.delivery_terms.company_not_responsible')}</span></p>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0">
                            <span className="c4">{t('terms_and_conditions.refund_cancellation.title')}</span>
                        </li>
                    </ol>
                    <p className="c0 c2"><span className="c9 c23"></span></p>
                    <p className="c0">
                        <span className="c9">
                            <Trans i18nKey='terms_and_conditions.refund_cancellation.right_of_withdrawal' components={[<a href="mailto:contact@behappiz.com" target="_blank" style={{ textDecoration: "underline"}} rel="noopener noreferrer"></a>]} />
                        </span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span>
                            <Trans i18nKey='terms_and_conditions.refund_cancellation.subscription_cancellation' components={[<a href="mailto:contact@behappiz.com" target="_blank" style={{ textDecoration: "underline"}} rel="noopener noreferrer"></a>]} />
                        </span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span>{t('terms_and_conditions.refund_cancellation.suspension_and_termination')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0">
                            <span className="c4">{t('terms_and_conditions.intellectual_property.title')}</span>
                        </li>
                    </ol>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <p className="c0"><span>{t('terms_and_conditions.intellectual_property.content_protection')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span>{t('terms_and_conditions.intellectual_property.brand_protection')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span>{t('terms_and_conditions.intellectual_property.content_ownership')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span>{t('terms_and_conditions.intellectual_property.unauthorized_use')}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0">
                        <span>
                             <Trans i18nKey='terms_and_conditions.intellectual_property.license_and_usage'></Trans>
                        </span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0"><span className="c4">{t("terms_and_conditions.guarantees")}</span></li>
                    </ol>
                    <p className="c0 c2 c13"><span className="c4"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.create_account")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <ul className="c3 lst-kix_list_5-0 start">
                        <li className="c0 c12 li-bullet-0"><span className="c1">{t("terms_and_conditions.provide_information")}</span></li>
                        <li className="c0 c12 li-bullet-0"><span className="c1">{t("terms_and_conditions.age_consent")}</span></li>
                        <li className="c0 c12 li-bullet-0"><span className="c1">{t("terms_and_conditions.no_contraindication")}</span></li>
                        <li className="c0 c12 li-bullet-0"><span className="c1">{t("terms_and_conditions.update_personal_info")}</span></li>
                    </ul>
                    <p className="c0 c2 c13"><span className="c1"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0"><span className="c4">{t("terms_and_conditions.medical_warning")}</span></li>
                    </ol>
                    <p className="c0 c2 c13"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.company_provider")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.health_problems")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.company_responsibility")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0"><span className="c4">{t("terms_and_conditions.effective_date_modifications")}</span></li>
                    </ol>
                    <p className="c0 c2 c13"><span className="c4"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.effective_date")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.modification_conditions")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.acceptance_modifications")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.impact_subscription")}</span></p>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0"><span className="c4">Contact </span></li>
                    </ol>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span
                        className="c1">Pour toute suggestion, réclamation ou demande d'information en lien avec l'utilisation de l'Application, du Site ou des Services, l'Utilisateur peut contacter la Société :</span>
                    </p>
                    <ul className="c3 lst-kix_list_3-0 start">
                        <li className="c0 c12 li-bullet-0"><span
                            className="c19">{t("terms_and_conditions.contact_by_email")}</span><span
                            className="c21"><a className="c7"
                                               href="mailto:contact@behappiz.com">contact@behappiz.com</a></span></li>
                        <li className="c0 c12 li-bullet-0"><span
                            className="c1">
                            {t("terms_and_conditions.contact_by_web")}
                            <a className="c7"
                               href="https://www.behappiz.com" target="_blank" rel="noopener noreferrer">www.behappiz.com</a>
                        </span>
                        </li>
                    </ul>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0" id="h.gjdgxs"><span className="c4">{t("terms_and_conditions.privacy_policy.data_privacy")}</span></li>
                    </ol>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <p className="c0">
                        <span>{t("terms_and_conditions.privacy_policy.data_privacy_content")}</span>
                    </p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0"><span className="c4">{t("terms_and_conditions.privacy_policy.miscellaneous")}</span></li>
                    </ol>
                    <p className="c0 c2"><span className="c4"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.privacy_policy.miscellaneous_content_1")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.privacy_policy.miscellaneous_content_2")}</span></p>
                    <p className="c0 c2 c13"><span className="c4"></span></p>
                    <ol className="c3 lst-kix_list_2-0">
                        <li className="c0 c12 li-bullet-0"><span className="c4">{t("terms_and_conditions.privacy_policy.applicable_law")}</span></li>
                    </ol>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.privacy_policy.applicable_law_content_1")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.privacy_policy.applicable_law_content_2")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.privacy_policy.dispute_resolution_content_1")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <p className="c0"><span className="c1">{t("terms_and_conditions.privacy_policy.dispute_resolution_content_2")}</span></p>
                    <p className="c0 c2"><span className="c1"></span></p>
                    <div><p className="c2 c18"><span className="c1"></span></p>
                        <p className="c2 c24"><span className="c1"></span></p></div>
                </WidthContainer>

            </div>
            <Footer/>

        </AppWrapper>
    )
}
